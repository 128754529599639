<template>
  <div class="modal modal-center fade ingredient-edit-modal"
       :class="showModal ? 'show' : ''"
       :style="showModal ? 'display: block;' : 'display :none; '"
       id="ingredientEditModal"
       tabindex="-1"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-ml h-30 my-0">
      <div class="modal-content h-100 w-100">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <h5 class="modal-title" id="exampleModalLabel">Subscription</h5>
          <button
            type="button"
            class="close m-0 p-0 position-static"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="text-dark" @click="closeModal">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div class="container">
            <div>
              <div class="required">
                <label for="subsrciption_type" class="form-label">Select Subscription Type</label>
              </div>
              <select class="form-control" v-model="subscriptionDetails.stripe_product_id" :class="{ 'is-invalid' : form.$errors.has('stripe_product_id')}">
                <option value="" selected disabled>Select Subscription</option>
                <option v-for="(products) in stripeProducts" :key="products.id" :value="products.id">{{products.name}}</option>
              </select>
              <div class="invalid-feedback" v-show="form.$errors.has('stripe_product_id')">{{ form.$errors.first('stripe_product_id') }}</div>
              <div class="required">
                <label for="subscription_id" class="mt-2">Enter Subscription ID</label>
              </div>
              <input type="text" id="subscription_id" placeholder="Subscription Id..." class="form-control" :class="{ 'is-invalid' : form.$errors.has('stripe_subscription_id')}" v-model="subscriptionDetails.stripe_subscription_id">
              <div class="invalid-feedback" v-show="form.$errors.has('stripe_subscription_id')">{{ form.$errors.first('stripe_subscription_id') }}</div>
              <label for="coupon_applicable" class="mt-3 mr-2">Coupon Applicable : </label>
              <input type="radio" id="coupon_applicable" :value=1 v-model="subscriptionDetails.coupon_applicable" :class="{ 'is-invalid' : form.$errors.has('missing_coupon_status')}"><label class="mr-3 ml-1">Yes</label>
              <input type="radio" id="coupon_applicable" :value=0 v-model="subscriptionDetails.coupon_applicable" :class="{ 'is-invalid' : form.$errors.has('missing_coupon_status')}" checked><label class="ml-1">No</label>
              <div class="invalid-feedback" v-show="form.$errors.has('missing_coupon_status')">{{ form.$errors.get('missing_coupon_status') }}</div>
              <div v-if="subscriptionDetails.coupon_applicable" class="required">
                <label for="coupon" class="mt-2">Enter Coupon Code</label>
                <input type="text" id="coupon" class="form-control" placeholder="Enter Coupon..." v-model="subscriptionDetails.coupon_code">
              </div>

              <div class="form-group mt-2 required">
              <label for="input-trial_ends_at">Subscription ends at</label>
              <div class="input-group">
              <flat-pickr
                id="input-subscription_ends_at"
                v-model="subscriptionDetails.subscription_ends_at"
                :config="startConfig"
                class="form-control"
                :class="{ 'is-invalid' : form.$errors.has('subscription_ends_at')}"
                placeholder="Select subscription end date"
                name="subscription_ends_at"
              />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary"
                          type="button"
                          title="Toggle"
                          :class="{ 'is-invalid' : form.$errors.has('subscription_ends_at')}"
                          data-toggle>
                    <i class="fa fa-calendar-alt"/>
                  </button>
                </div>
                <div
                  class="invalid-feedback"
                  v-show="form.$errors.has('subscription_ends_at')"
                >
                  {{ form.$errors.first('subscription_ends_at') }}
                </div>
              </div>
              </div>

            </div>
                <div class="form-group mt-2">
                  <button class="btn btn-primary" @click="addSubscription">Add Subscription</button>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import Form from 'laravel-form-validation';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component';
import dayjs from 'dayjs';
export default {
  name: 'SubscriptionEditModal',
  components: {flatPickr},
  props: {
    user: {
      type: Object,
      default: null
    },
    stripeProducts:{
      type: Array,
      default: null
    }
  },
  data() {
    return{
      merchant: this.user,
      showModal: true,
      subscriptionDetails: {
        admin_id: this.user.id,
        stripe_subscription_id: null,
        stripe_product_id: null,
        coupon_applicable: false,
        coupon_code: null,
        subscription_ends_at: null
      },
      form: new Form(),
      startConfig: {
        wrap: true,
        disableMobile: true,
        minDate: dayjs().toString(),
        maxDate: null,
        enableTime: true,
        altFormat: 'M j, Y G:i K',
        altInput: true,
        dateFormat: 'Y-m-d H:i:S',
        plugins: [new ConfirmDatePlugin()],
      },
    }
  },
  methods: {
    addSubscription(){
      this.form.post(route('admin.merchant.update.subscription'), this.subscriptionDetails)
        .then((response) => {
          if(response.error){
            this.$toast.error('Subscription Already Exists')
            return;
          }
          this.merchant.subscriptions = response.subscriptions
          this.$toast.success('Subscription Added Successfully')
          this.$emit('closeModal')
          this.$emit('updatedUser',this.merchant)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false;
        })
    },
    closeModal() {
      this.$emit('closeModal');
    },
  }
  }
</script>

<style scoped>
.ingredient-edit-modal .modal-content {
  min-height: 465px;
}
.scale-checkbox label {
  margin-top: 3px;
}
</style>
