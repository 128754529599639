<template>
  <div class="content-wrapper">
    <section class="content">
      <div class="row">
        <div class="col-12">
          <div class="card mt-2">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col-6">
                  <div class="">
                    <a class="btn btn-secondary" :href="route('admin.merchant.index')">Back</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <p>{{this.editingUser.name}}'s Subscription Details</p>
              <p>Customer ID : {{this.editingUser.stripe_customer_id}}</p>
              <button class="btn btn-sm btn-primary" @click="showSubscriptionEditModal()">Add Subscription</button>
              <div class="table-wrapper table-responsive" v-if="editingUser.subscriptions.length > 0">
                <table id="table" class="table table-striped table-bordered table-hover mb-2" >
                  <thead>
                  <tr>
                    <th v-for="(column, key, index) in columns" :key="index">
                      <span>
                      {{ column }}
                      </span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(subscriptions,index) in userSubscriptions" :key="index">
                    <td>
                        {{subscriptions.stripe_subscription_id}}
                    </td>
                    <td>
                        {{subscriptions.product.name}}
                    </td>
                    <td v-if="subscriptions.is_expired">
                     <p class="text-danger">Expired</p>
                    </td>
                    <td v-else>
                    <p class="text-success">Valid</p>
                    </td>
                    <td>
                        {{new Date(subscriptions.created_at).toLocaleDateString()}}
                    </td>
                    <td>
                        {{new Date(subscriptions.updated_at).toLocaleDateString()}}
                    </td>
                    <td>
                      <div class="btn-group btn-group-sm">
                      <button class="btn btn-sm btn-danger" @click="deleteSubscription(index,subscriptions.id)"><i class="fas fa-trash"></i></button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p>No Results Found</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <subscription-edit-modal v-if="subscriptionEditModal"
                               @closeModal="closeModal"
                               @updatedUser="updatedUser"
                               :user="editingUser"
                               :stripe-products="stripeProducts"
      />
    </section>
  </div>
</template>

<script>
import SubscriptionEditModal from './Modal/SubscriptionEditModal.vue';
import Form from 'laravel-form-validation';
import flatPickr from 'vue-flatpickr-component';
export default {
  name: 'SubscriptionView',

  components: {SubscriptionEditModal, flatPickr},

  props:{
    authUser:{
      type: Object,
      default: null
    },
    editingUser:{
      type: Object,
      default: null
    },
    stripeProducts:{
      type: Array,
      default: null
    }
  },
  data(){
    return{
      columns:{
        subscription_id: 'Subscription ID',
        subscription_type: 'Subscription Type',
        subscription_status: 'Subscription Status',
        created_at: 'Created At',
        updated_at: 'Updated At',
        action: 'Action'
      },
      userSubscriptions: this.editingUser.subscriptions,
      subscriptionEditModal: false,
      form: new Form()
    }
  },
  methods:{
    updatedUser(user){
      this.userSubscriptions = user.subscriptions;
    },
    async deleteSubscription(index,subscriptionId){
      const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this subscription?', {
        title: 'Are you sure?',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
      })

      if(!confirm) {
        return;
      }
      this.userSubscriptions.splice(index,1);
      this.form.delete(route('admin.merchant.delete.subscription'),{'subscription_id' : subscriptionId}).then((response) => {
        this.$toast.success('Subscription Deleted Successfully')
      })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false;
        });
    },
    closeModal(){
      this.subscriptionEditModal = false;
    },
    showSubscriptionEditModal(){
      this.subscriptionEditModal = true;
    }
  },
}
</script>
