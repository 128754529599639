import { render, staticRenderFns } from "./MerchantIndex.vue?vue&type=template&id=3cfc077a&"
import script from "./MerchantIndex.vue?vue&type=script&lang=js&"
export * from "./MerchantIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.9.8_vue-template-compiler@2.6.14/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports